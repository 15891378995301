import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { icons } from "src/assets/icons-new";
import PropTypes from "prop-types";

const Toaster = ({ severity, message, customOptions }) => {

  if (!message || message.length === 0) {
    return; // Return early if message is empty or undefined
  }

  const CustomCloseButton = ({ closeToast }) => {
    const handleClose = () => {
      if (!customOptions?.closeOnClick) {
        customOptions?.onClose?.(closeToast);
      } else {
        closeToast();
      }
    };

    return (
      <button
        className={`Toastify__toast-close severity-icon-${severity}`}
        onClick={handleClose}
      >
        <FontAwesomeIcon icon={icons.closeLight} />
      </button>
    );
  };


  //custom options
  const options = {
    position: "top-right",
    autoClose: 5000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    containerClassName: `CustomToastify__toast-container severity-${severity}`,
    className: `CustomToastify__toast severity-${severity}`, // Custom class for individual toast
    bodyClassName: "CustomToastify__toast-body", // Custom class for the toast body
    closeButton: <CustomCloseButton />,
    ...customOptions
  };

  switch (severity) {
    case 0:
      toast.success(message, {
        ...options,
        icon: (
          <FontAwesomeIcon
            icon={icons.successStateLight}
            className={`severity-icon-${severity} fs-18`}
          />
        ),
      }); // success state for severity 0
      break;
    case 10:
      toast.info(message, {
        ...options,
        icon: (
          <FontAwesomeIcon
            icon={icons.infoStateLight}
            className={`severity-icon-${severity} fs-18`}
          />
        ),
      }); // info state for severity 10
      break;
    case 90:
      toast.warning(message, {
        ...options,
        icon: (
          <FontAwesomeIcon
            icon={icons.warningStateLight}
            className={`severity-icon-${severity} fs-18`}
          />
        ),
      }); // warn state for severity 90
      break;
    case 100:
      toast.error(message, {
        ...options,
        icon: (
          <FontAwesomeIcon
            icon={icons.errorStateLight}
            className={`severity-icon-${severity} fs-18`}
          />
        ),
      }); // error state for severity 100
      break;
    default:
      break; // Default case for a general toast
  }
};

export default Toaster;

Toaster.propTypes = {
  severity: PropTypes.number,
  message: PropTypes.string,
  customOptions: PropTypes.object,
  closeToast: PropTypes.func
}

Toaster.defaultProps = {
  severity: PropTypes.number,
  message: PropTypes.string,
}
