import AuthAxios from 'src/app/helpers/AxiosAuth';
import { leadManagement } from 'src/config';

export const AGENT_REFERRAL_REQUEST = 'AGENT_REFERRAL_REQUEST';
export const AGENT_REFERRAL_SUCCESS = 'AGENT_REFERRAL_SUCCESS';
export const AGENT_REFERRAL_FAILURE = 'AGENT_REFERRAL_FAILURE';

export const getReferralDetails = () => async (dispatch) => {
  try {
    dispatch({ type: AGENT_REFERRAL_REQUEST });

    const response = await AuthAxios.get(`${leadManagement}/referrals`);
    dispatch({
      type: AGENT_REFERRAL_SUCCESS,
      payload: response.data?.entity,
    });
  } catch (error) {
    dispatch({
      type: AGENT_REFERRAL_FAILURE,
      payload: error?.response?.data?.message || 'Error to fetch modal data',
    });
  }
};

export const sendReferralEmail = async (email) => {
  try {
    const result = await AuthAxios.post(
      `${leadManagement}/referrals/sendReferralEmail?email=${email}`,
    );
    return result.data;
  } catch (err) {
    console.error('ERROR', err);
    throw err;
  }
};
