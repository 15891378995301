import React from "react";
import { Route, Switch, BrowserRouter } from "react-router-dom";
import "../scss/style.scss";
import AuthAxios from "./helpers/AxiosAuth";
import Swal from "sweetalert2";

import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { reactPlugin } from "./utils/appInsights";
import Cookies from "universal-cookie";
import BuSelectionPage from "./containers/loginFlow/BuSelectionPage";
import LeadCenterSSORedirect from "./containers/ssoLoginFlow/LeadCenterSSORedirect";
import "react-datepicker/dist/react-datepicker.css";
import { toast, ToastContainer } from "react-toastify";
import { getAccessToken } from "./utils/helperMethods";
import { useDispatch } from "react-redux";
import { setIsListScreenError, setIsViewEditScreenError } from "./redux/actions/actions";

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
);

// Containers
const TheLayout = React.lazy(() => import("./containers/TheLayout"));

// Pages
const Page404 = React.lazy(() => import("./components/page404/Page404"));
const Page500 = React.lazy(() => import("./components/page500/Page500"));
const Login = React.lazy(() => import("./components/login/Login"));
const ServerLogin = React.lazy(() => import("./auth/ServerLoginPage"));
const ServerLogoutPage = React.lazy(() => import("./auth/ServerLogoutPage"));
const ErrorPage = React.lazy(() => import("./components/home/ErrorPage"));
const Terms = React.lazy(() => import("./components/login/Terms"));
const Privacy = React.lazy(() => import("./components/login/Privacy"));
const Ccpa = React.lazy(() => import("./components/login/Ccpa"));

const SigninOidc = React.lazy(() =>
  import("./components/login/SignInCallback")
);
const SignoutOidc = React.lazy(() =>
  import("./components/login/SignOutCallback")
);
const SilentSigninOidc = React.lazy(() =>
  import("./components/login/silent-Callback")
);
const cookies = new Cookies();

const App = () => {
  const dispatch = useDispatch();
  AuthAxios.interceptors.response.use(
    function (response) {
      dispatch(setIsListScreenError(false));
      dispatch(setIsViewEditScreenError(false));
      return response;
    },
    function (error) {
      //Severity 110 = "Application" when 110, show 
      if (error?.response?.data?.Severity === 110) {
        dispatch(setIsListScreenError(true));
        dispatch(setIsViewEditScreenError(true));
      }
      if (error?.response?.status === 401) {
        Swal.fire({
          text: "Your current session has expired, please login again.",
          icon: "info",
          allowOutsideClick: false,
        }).then((res) => {
          if (res.isConfirmed) {
            localStorage.clear();
            sessionStorage.clear();
            refreshCacheAndReload();
            if (cookies.get("client_url") !== undefined) {
              window.location = cookies.get("client_url") + "/signout-oidc";
            } else {
              window.location = "/signout-oidc";
            }
          }
        });
      }
      return Promise.reject(error);
    }
  );

  AuthAxios.interceptors.request.use(async (config) => {
    const access_token = getAccessToken();
    const reftoken = localStorage.getItem("ref_token");

    config.headers.Authorization = access_token ? `Bearer ${access_token}` : "";
    config.headers.Ref_Token = reftoken ? `${reftoken}` : "";

    return config;
  });

  const refreshCacheAndReload = () => {
    if (caches) {
      caches.keys().then((names) => {
        for (const name of names) {
          caches.delete(name);
        }
      });
    }
    window.location.reload(true);
  };
  toast.configure();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <BrowserRouter>
        <React.Suspense fallback={loading}>
          <ToastContainer />
          <Switch>
            <Route
              exact
              path="/login"
              name="Login Page"
              render={(props) => <Login {...props} />}
            />
            <Route
              exact
              path="/serverlogin"
              name="Login Page"
              render={(props) => <ServerLogin {...props} />}
            />
            <Route
              exact
              path="/bu"
              name="Bu Selection Page"
              render={(props) => <BuSelectionPage {...props} />}
            />
            <Route
              exact
              path="/LeadCenterSSO"
              name="LeadCenterSSO"
              render={(props) => <LeadCenterSSORedirect {...props} />}
            />
            <Route exact path="/logout" name="Logout Page">
              <ServerLogoutPage />
            </Route>
            <Route
              exact
              path="/signin-oidc"
              name="Login Page"
              render={(props) => <SigninOidc {...props} />}
            />
            <Route
              exact
              path="/error"
              name="Home Page"
              render={(props) => <ErrorPage {...props} />}
            />
            <Route
              exact
              path="/terms"
              name="Terms and Conditions"
              render={(props) => <Terms {...props} />}
            />
            <Route
              exact
              path="/privacy"
              name="Privacy Policy"
              render={(props) => <Privacy {...props} />}
            />
            <Route
              exact
              path="/privacy-ccpa"
              name="Rights And Choices"
              render={(props) => <Ccpa {...props} />}
            />
            <Route
              exact
              path="/signout-oidc"
              name="Login Page"
              render={(props) => <SignoutOidc {...props} />}
            />
            <Route
              exact
              path="/silent"
              name="Login Page"
              render={(props) => <SilentSigninOidc {...props} />}
            />
            <Route
              exact
              path="/404"
              name="Page 404"
              render={(props) => <Page404 {...props} />}
            />
            <Route
              exact
              path="/500"
              name="Page 500"
              render={(props) => <Page500 {...props} />}
            />
            <Route
              path="/"
              name="Dashboard"
              render={(props) => <TheLayout {...props} />}
            />
          </Switch>
        </React.Suspense>
      </BrowserRouter>
    </AppInsightsContext.Provider>
  );
};

export default App;
